import React from 'react'

import plumbing from '../../img/services/plumbing.jpg'
import plumbingThumb from '../../img/services/plumbing_thumb.jpg'
import Layout from '../../components/layout'

const Plumbing = () => (
  <Layout>
    <div className="box">
      <h2 className="stripe">Plumbing Services</h2>

      <div className="serviceImage">
        <a className="fancybox" href={plumbing} title="Restored Spanish Timber Vanity" rel="fancybox">
          <img src={plumbingThumb} width="250"/>
        </a>
        <p>Restored Spanish Timber Vanity</p>
      </div>

      <p>
        Our plumbing department is run by a full time certifying plumber taking care of all your plumbing needs. These
        needs include small or large maintenance work right through to new builds and renovations.
      </p>

      <div id="tvservicesListOne" style={{ float: 'left' }}><h3>Maintenance includes:</h3>
        <ul className="servicesList">
          <li>Hot water cylinders.</li>
          <li>Bathroom/ kitchen renovations.</li>
          <li>All spouting and downpipes.</li>
          <li>Tap washers.</li>
          <li>Log Fires.</li>
          <li>New flues for existing log fires.</li>
          <li>Roof flashings.</li>
        </ul>
      </div>

      <div id="tvservicesListTwo" style={{ float: 'left', marginLeft: '20px' }}><h3>New Homes:</h3>
        <ul className="servicesList">
          <li>Renovations kitchen/bathroom.</li>
          <li>Complete rebuilds (pre pipes).</li>
          <li>Wet backs.</li>
          <li>Solar hot water.</li>
          <li>Running water mains.</li>
        </ul>
      </div>

      <br style={{ clear: 'left' }}/>

      <div id="tvservicesBottomText" style={{ float: 'left', width: '600px' }}>
        <p>
          All our work is guaranteed and carried out by plumbers who are licensed under the Plumbing, Drainlaying and
          Gasfitters Board. All our quotes are free and no obligation.
        </p>
      </div>

      <br className="clear"/>
    </div>
  </Layout>
)

export default Plumbing
